import { domPixelRatio } from "md-base-tools/env";
import MDRouter from "@/service/router";
export default {
  props: {
    pro_info: {
      // url | name | pro_id
      type: Object
    }
  },
  data() {
    return {
      domPixelRatio
    };
  },
  methods: {
    // 去详情
    goToInfo() {
      MDRouter.goProInfo(this.pro_info.pro_id);
    }
  }
};