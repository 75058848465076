import MDRouter from "@/service/router";
import { useRoute } from 'vue-router';
import { Dialog,
// AddressEdit,
Form, Image as VImage, Field, Switch,
// eslint-disable-next-line no-unused-vars
Uploader, showToast } from 'vant';
// eslint-disable-next-line no-unused-vars
import { post, get, OSSUpload } from '@/utils/apiBase.js';
import { mapState, useStore } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { getOrderDetail, mdaftersaleAppeal_order, showVideoList } from "@api/index";
import lowerdev from '@/service/lowerdev';
import { env, domClientWidth, domPixelRatio, domTop, domHeight, domClientHeight } from "md-base-tools/env";
import CompProCard from "@@/after_sale/pro_card.vue";
export default {
  name: 'orderList',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    [Dialog.name]: Dialog.Component,
    VImage,
    Uploader,
    Form,
    // eslint-disable-next-line vue/no-unused-components
    Field,
    // eslint-disable-next-line vue/no-unused-components
    Switch,
    CompProCard
  },
  async setup() {
    const route = useRoute();
    // eslint-disable-next-line no-unused-vars
    const store = useStore();
    const aftersale_id = route.params.id ? route.params.id : null;
    const orderId = route.query.order_id ? route.query.order_id : null;
    let PInfo = {};
    if (sessionStorage.getItem('product_info')) {
      PInfo = JSON.parse(sessionStorage.getItem('product_info'));
      sessionStorage.clear('product_info');
    } else {
      const res = await getOrderDetail({
        id: orderId,
        type: "order_id"
      });
      if (res.status !== 0) {
        return showToast({
          type: "text",
          message: res.message,
          onClose: () => {
            window.history.back();
          }
        });
      }
      PInfo = res.data.product_info;
    }
    return {
      aftersale_id,
      PInfo,
      orderId
    };
  },
  data() {
    return {
      domPixelRatio,
      // eslint-disable-next-line no-undef
      showPicker: false,
      readyBtn: false,
      dialogImgShow: false,
      orderRefundShow: false,
      imgBigUrl: '',
      show: false,
      addresid: '',
      dialogShow: false,
      env: env,
      quhao: false,
      idx: 0,
      postal_code: '',
      street: '',
      from: {
        apply_reson_content: '',
        aftersale_id: this.aftersale_id
      },
      isMobile: env.isMobile(),
      columns: [],
      columnsStreet: [],
      fileList: [],
      placeholder: '~@assets/images/orderlist/default_1x1@2x.png'
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem('miniProgram') === 'weixin') {
      lowerdev.dynamicScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js');
    }
  },
  mounted() {
    lowerdev.addbind(window, 'scroll', this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem('miniProgram') == 'weixin';
    if (sessionStorage.getItem('formDataA')) {
      const data = JSON.parse(sessionStorage.getItem('formDataA'));
      this.from = data.from;
      this.fileList = data.fileList;
      sessionStorage.setItem('formDataA', '');
    }
  },
  watch: {
    from: {
      handler(newValue) {
        sessionStorage.setItem('formDataA', JSON.stringify({
          from: this.from,
          fileList: this.fileList
        }));
        if (newValue.apply_reson_content) {
          this.readyBtn = true;
        } else {
          this.readyBtn = false;
        }
      },
      deep: true
    }
  },
  beforeUnmount() {
    lowerdev.removebind(window, 'scroll', this.reloadScroll);
  },
  methods: {
    appUpdate() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "chooseMedia",
        functionParams: {
          type: 3,
          count: 6 - this.fileList.length
        },
        callBack: ({
          errorCode,
          data
        }) => {
          console.log('errorCode', errorCode);
          console.log(888, data);
          if (errorCode == 0) {
            console.log('data', data);
            if (data.type == 1) {
              data.image_list.forEach(e => {
                this.fileList.push({
                  file_type: 'img',
                  url: e
                });
              });
            } else {
              const _this = this;
              const index = this.fileList.length;
              this.fileList.push({
                file_type: 'video',
                url: data.video.video_url,
                video_cover: data.video.video_cover
              });
              let num = 0;
              const fun = () => {
                const IMG = new Image();
                IMG.onload = function () {
                  _this.fileList[index].loaded = true;
                  _this.fileList[index].video_cover = data.video.video_cover;
                  console.log('成功', _this.fileList);
                };
                IMG.onerror = function () {
                  if (num < 5) {
                    setTimeout(() => {
                      fun();
                      num++;
                    }, 2000);
                  } else {
                    _this.fileList[index].loaded = true;
                    _this.fileList[index].video_cover = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
                  }
                };
                IMG.src = data.video.video_cover;
              };
              fun();
            }
          }
        },
        webFun: () => {}
      });
    },
    async openBig(item) {
      this.imgBigUrl = item;
      if (item.file_type == 'video') {
        await this.showVideoListFn(item.url);
        this.dialogImgShow = true;
      } else {
        this.dialogImgShow = true;
      }
    },
    async showVideoListFn(url) {
      const {
        data
      } = await showVideoList({
        url
      });
      this.imgBigUrl.videoUrl = data.list.OD;
    },
    deleteImg(idx) {
      this.fileList.splice(idx, 1);
    },
    async readyBtnSubmit() {
      const obj = JSON.parse(JSON.stringify(this.from));
      obj.apply_attachment = JSON.stringify(this.fileList);
      if (!this.readyBtn) throw new Error('请填写必填项');
      const {
        status,
        message
      } = await mdaftersaleAppeal_order(obj);
      if (status) {
        showToast({
          type: "text",
          message: message,
          onClose: () => {}
        });
      } else {
        showToast({
          type: "text",
          message: '操作成功',
          onClose: () => {
            // 跳转至售后详情
            const url = `/after_sale/detail?order_id=${this.orderId}&full_screen=true`;
            MDRouter.Link(url, 'wap');

            // eslint-disable-next-line no-undef
            graft.app({
              functionName: "closeController",
              webFun: () => {
                history.back();
              }
            });
          }
        });
      }
    },
    async afterRead(event) {
      if (event.length) {
        event.forEach((e, ix) => {
          this.fileList.push({
            file_type: e.file.type.includes('video') ? 'video' : 'img'
          });
          if (e.file.type.includes('video')) {
            let option = {
              status: 1,
              success: (item, cover, idx, bol, list) => {
                this.onSuccess({
                  item,
                  idx: this.fileList.length - 1 + ix,
                  list,
                  cover
                });
              },
              error: (e, msg) => {
                showToast({
                  type: "text",
                  message: msg,
                  onClose: () => {}
                });
              }
            };
            let fileList = [e.file];
            OSSUpload(option, fileList, function (p) {
              console.log(p);
            });
          } else {
            let option = {
              status: 0,
              success: (item, idx, bol, list, info) => {
                this.onSuccess({
                  item,
                  idx: this.fileList.length - 1 + ix,
                  list,
                  info
                });
              },
              error: (e, msg) => {
                showToast({
                  type: "text",
                  message: msg,
                  onClose: () => {}
                });
              }
            };
            let fileList = [e.file];
            OSSUpload(option, fileList);
          }
        });
      } else {
        this.fileList.push({
          file_type: event.file.type.includes('video') ? 'video' : 'img'
        });
        if (event.file.type.includes('video')) {
          let option = {
            status: 1,
            success: (item, cover, idx, bol, list) => {
              console.log(cover);
              this.onSuccess({
                item,
                idx: this.fileList.length - 1,
                list,
                cover
              });
            },
            error: (e, msg) => {
              console.log(e);
              showToast({
                type: "text",
                message: msg,
                onClose: () => {}
              });
              event.target.value = '';
            }
          };
          let fileList = [event.file];
          OSSUpload(option, fileList, function (p) {
            console.log(p);
          });
        } else {
          let option = {
            status: 0,
            success: (item, idx, bol, list, info) => {
              this.onSuccess({
                item,
                idx: this.fileList.length - 1,
                list,
                info
              });
            },
            error: (e, msg) => {
              console.log(e);
              showToast({
                type: "text",
                message: msg,
                onClose: () => {}
              });
              event.target.value = '';
            }
          };
          let fileList = [event.file];
          OSSUpload(option, fileList);
        }
      }
    },
    onSuccess(item) {
      this.fileList[item.idx].url = item.item;
      console.log(JSON.stringify(this.fileList));
      if (this.fileList[item.idx].file_type == 'video') {
        const _this = this;
        let num = 0;
        const fun = () => {
          const IMG = new Image();
          IMG.onload = function () {
            _this.fileList[item.idx].loaded = true;
            _this.fileList[item.idx].video_cover = item.cover;
            console.log('成功', _this.fileList);
          };
          IMG.onerror = function () {
            console.log('失败');
            if (num < 5) {
              setTimeout(() => {
                fun();
                num++;
              }, 2000);
            } else {
              _this.fileList[item.idx].loaded = true;
              _this.fileList[item.idx].video_cover = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
            }
          };
          IMG.src = item.cover;
        };
        fun();
      }
    },
    dialogBack() {
      this.orderRefundShow = false;
    },
    op(type) {
      this.orderRefundShow = false;
      this.from.apply_reson_type = type.name;
    },
    onConfirmquhao(e, r) {
      this.quhao = false;
      this.idx = r;
      this.from.code_posi = `${this.codeList[r].name} +${this.codeList[r].id}`;
    },
    onSubmit() {
      this.addAddress();
    },
    onClose() {
      this.show = false;
    },
    onCancel(e) {
      console.log(e);
    },
    onConfirm(e) {
      this.pro_posi = e[0].name;
      this.province_id = e[0].id;
      this.city_posi = e[1].name;
      this.city_id = e[1].id;
      this.region_posi = e[2].name;
      this.county_id = e[2].id;
      this.from.area = `${e[0].name}/ ${e[1].name}/ ${e[2].name}`;
      this.new_address_street();
      this.show = false;
    },
    StreetChange(e) {
      this.street = e.name;
      this.from.street = e.name;
      this.showPicker = false;
    },
    close() {
      this.dialogShow = true;
    },
    dropBack() {
      history.back();
      // if (history.length > 1) {
      //   this.$router.back(-1)
      // } else {
      //   lowerdev.openUrl()
      // }
    },
    reloadScroll() {
      const localTop = domTop();
      if (this.loading) {
        return false;
      }
      if (localTop && domHeight() < localTop + domClientHeight() + 50) {
        this.loading = true;
        this.page_index = this.page_index + 1;
      }
    },
    // 重新加载图片
    reloadImg(item) {
      setTimeout(() => {
        if (item.file_type == 'video') {
          item.video_cover = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
        } else {
          item.url = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
        }
      }, 3000);
    }
  }
};